import TomSelect from 'tom-select'
import 'tom-select/dist/css/tom-select.bootstrap5.css'

/**
 * @param {HTMLInputElement} selector
 */
function initializeTomSelect (selector) {
  if (selector === null || selector.tomselect != null || selector.value == null) return

  // eslint-disable-next-line no-new
  new TomSelect(selector, { refreshThrottle: 0 })
}

function initializeForm () {
  for (const element of document.querySelectorAll('.tom-select')) {
    initializeTomSelect(element)
  }
}

document.addEventListener('turbo:render', initializeForm)
document.addEventListener('turbo:load', initializeForm)
