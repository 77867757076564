// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/packs and only use these pack files to reference
// that code so it'll be compiled.

import '@hotwired/turbo-rails'
import 'channels'
import '@popperjs/core'
import { Tooltip } from 'bootstrap'
import 'meters'

import 'bootstrap-icons/bootstrap-icons.svg'

document.addEventListener('turbo:load', function () {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
})
